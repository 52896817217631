export const agentAPI = "https://yog-robot.com/yog-robot";

export const firebaseConfig = {
    apiKey: "AIzaSyBpmkFfL8OqhotFd3Xeg7vQXuFLmKcOwZE",
    authDomain: "yogbot-2d3a4.firebaseapp.com",
    databaseURL: "https://yogbot-2d3a4.firebaseio.com",
    projectId: "yogbot-2d3a4",
    storageBucket: "yogbot-2d3a4.appspot.com",
    messagingSenderId: "900133155210",
    appId: "1:900133155210:web:8b8add16d6ae311af4d873",
    measurementId: "G-30857E7654"
};